import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'
import Button from '../components/Button/Button'
import Text from '../components/Text/Text'
import Underline from '../components/Underline/Underline'
import CtA from '../components/CTA/CTA'
import Title from '../components/Title/Title'
import TxtImg from '../components/TxtImg/TxtImg'
import * as ServicesStyles from './services.module.css'


function Services({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>Services | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={ServicesStyles.title}>
                <Title>
                    <h1>Supporting the Health of Your Body with Our Services</h1>
                    <h2>Find out more about the different ways that we can help you maximise your body's wellbeing.</h2>
                </Title>
            </section>
            <section className="condense">
                <Text>
                    <Underline>
                        <h2>Our Services</h2>
                    </Underline>
                    <p>Brixworth Osteopathic Clinic offers care for patients of all ages who may be suffering from a range of health issues.  As highly trained primary care practitioners we use refined diagnostic skills and experience to treat the cause not just the symptoms of the problem.</p>
                    <p>We spend the time needed to understand you, the individual, to develop a tailored well rounded treatment & rehabilitation programme, giving advice, education and exercises as part of our care.</p>
                </Text>
            </section>
            <section className="condense">
                <TxtImg split="half" imgFirst={false} imgSrc={data.osteopathy} imgAlt="Osteopath treating a patient">
                    <Underline>
                        <h2>Osteopathy</h2>
                    </Underline>
                    <p>Osteopathy is a method of assessing, treating and preventing a wide range of health problems. Osteopaths combine many different techniques, all with the aim of improving function, relieving pain and aiding recovery.</p>
                    <p>We adopt a person-centred approach to make sure that we understand you and can tailor your rehabilitation based on your individual needs. We believe that this is how we can create the optimum conditions for restoration of health.</p>
                    <CtA to="/services/osteopathy" text="Learn more about Osteopathy" />
                </TxtImg>
            </section>
            <section className="condense">
                <TxtImg split="half" imgFirst={true} imgSrc={data.massage} imgAlt="Patient's lower leg receiving massage treatment">
                    <Underline>
                        <h2>Sports & Remedial Massage</h2>
                    </Underline>
                    <p>Sports, remedial or deep tissue massage can provide many benefits, whether you're aiming to optimise performance in your chosen sport or occupation, or you want to find help to simply move more freely and without pain.</p>
                    <p>At Brixworth Osteopathic Clinic we use a variety of advanced massage techniques to maximise the body's performance and wellbeing. Following an initial consultation, your treatment will be specific and tailored to your individual needs.</p>
                    <CtA to="/services/sports-remedial-massage" text="Learn more about massage" />
                </TxtImg>
            </section>
            <section className="condense">
                <TxtImg split="half" imgFirst={false} imgSrc={data.cranial} imgAlt="Baby lying on his front on the treatment table">
                    <Underline>
                        <h2>Cranial Osteopathy</h2>
                    </Underline>
                    <p>Cranial osteopathy is a subtle and refined approach to osteopathy that uses gentle techniques and incorporates the anatomy and physiology of the head. Cranial osteopaths use a highly trained sense of touch to feel subtle changes of tension and tissue quality in the living anatomy of the whole body, and to diagnose areas of strain or dysfunction.</p>
                    <p>Diagnosis and treatment are intimately linked as the osteopath works to activate the innate ability of the body to heal itself, and by offering gentle and specific support where it is needed to bring the tissues into a state of balance and release, to restore it to health.</p>
                    <CtA to="/services/cranial-osteopathy/" text="Learn more about Cranial Osteopathy" />
                </TxtImg>
            </section>
            <section className="expand">
                <TxtImg split="half" imgFirst={true} imgSrc={data.feeding} imgAlt="Infant feeding coaching">
                    <Underline>
                        <h2>Infant Feeding Coaching</h2>
                    </Underline>
                    <p>The goal of infant feeding coaching is to aid and and support the feeding mother and baby to find the feeding experience they are both looking for by confidentially addressing fears, concerns and issues that the mother and/or partner may be experiencing.</p>
                    <p>Through the consultation you will explore infant feeding as a whole, its benefits and how you may include feeding into your lifestyle. No feeding experience is the same, with everyone having differing needs and expectations. You will work together with the Infant Feeding coach, including your partner and others who may support you as part of your and your babies support network.</p>
                    <CtA to="/services/infant-feeding-coaching/" text="Learn more about Infant Feeding Coaching" />
                </TxtImg>
            </section>
            <section className={ServicesStyles.banner}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        osteopathy: file(relativePath: {eq: "osteopathy.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
        massage: file(relativePath: {eq: "massage.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
        cranial: file(relativePath: {eq: "cranial.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
        feeding: file(relativePath: {eq: "infant-feeding.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Services